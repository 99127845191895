import IndividualSetStatistics2024 from "../../../../src/data/articles/2024-standard-rotation-cube-retrospective/IndividualSetStatistics.tsx";
import CardsVisualization2024 from "../../../../src/data/articles/2024-standard-rotation-cube-retrospective/CardsVisualization.tsx";
import SetStatistics2024 from "../../../../src/data/articles/2024-standard-rotation-cube-retrospective/SetStatistics.tsx";
import { ResponseExample2024 } from "../../../../src/data/articles/2024-standard-rotation-cube-retrospective/ResponseExample2024.tsx";
import SetList2024 from "../../../../src/data/articles/2024-standard-rotation-cube-retrospective/SetList.tsx";
import * as React from 'react';
export default {
  IndividualSetStatistics2024,
  CardsVisualization2024,
  SetStatistics2024,
  ResponseExample2024,
  SetList2024,
  React
};