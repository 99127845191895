export const sets = [
  {
    code: 'mid',
    name: 'Innistrad: Midnight Hunt',
    path: '/articles/cube-prospective-innistrad-midnight-hunt/',
    iconURL: 'https://svgs.scryfall.io/sets/mid.svg?1655092800',
  },
  {
    code: 'mic',
    name: 'Midnight Hunt Commander',
    path: '/articles/cube-prospective-midnight-hunt-commander/',
    iconURL: 'https://svgs.scryfall.io/sets/mic.svg?1655092800',
  },
  {
    code: 'vow',
    name: 'Innistrad: Crimson Vow',
    path: '/articles/cube-prospective-innistrad-crimson-vow/',
    iconURL: 'https://svgs.scryfall.io/sets/vow.svg?1655092800',
  },
  {
    code: 'voc',
    name: 'Crimson Vow Commander',
    path: '/articles/cube-prospective-crimson-vow-commander/',
    iconURL: 'https://svgs.scryfall.io/sets/voc.svg?1655092800',
  },
  {
    code: 'neo',
    name: 'Kamigawa: Neon Dynasty',
    path: '/articles/cube-prospective-kamigawa-neon-dynasty/',
    iconURL: 'https://svgs.scryfall.io/sets/neo.svg?1655092800',
  },
  {
    code: 'nec',
    name: 'Neon Dynasty Commander',
    path: '/articles/cube-prospective-neon-dynasty-commander/',
    iconURL: 'https://svgs.scryfall.io/sets/nec.svg?1655092800',
  },
  {
    code: 'snc',
    name: 'Streets of New Capenna',
    path: '/articles/cube-prospective-streets-of-new-capenna/',
    iconURL: 'https://svgs.scryfall.io/sets/snc.svg?1655092800',
  },
  {
    code: 'ncc',
    name: 'New Capenna Commander',
    path: '/articles/cube-prospective-new-capenna-commander/',
    iconURL: 'https://svgs.scryfall.io/sets/ncc.svg?1655092800',
  },
  {
    code: 'clb',
    name: "Commander Legends: Battle For Baldur's Gate",
    path: '/articles/cube-prospective-commander-legends-battle-for-baldurs-gate/',
    iconURL: 'https://svgs.scryfall.io/sets/clb.svg?1655092800',
  },
]
