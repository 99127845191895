import React from 'react'

import { CardsVisualization } from 'components/set-retrospectives/cards-visualization/CardsVisualization'

import data from 'raw-loader!./cards.csv'

import { sets } from './sets'

const ArticleCardsVisualization: React.FC = () => {
  return (
    <CardsVisualization
      cardsData={data}
      sets={sets.map((set) => set.code)}
      useStandardDeviations
    />
  )
}

export default ArticleCardsVisualization
