import * as styles from './ResponseExample2024.module.scss'

import React from 'react'

import { Response } from 'components/prospective-archive/Response'

import exampleResponseData from './example-response.json'

export const ResponseExample2024: React.FC = () => {
  return (
    <div className={styles.container}>
      <Response {...exampleResponseData} />
    </div>
  )
}
